<template>
    <div class="group relative flex flex-col">
        <Link :href="product.href">
            <Labels :product="product"/>
            <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-white -z-10">
                <img :src="imageSize(product.imageSrc, 500)" :alt="product.title" :class="imageClasses"
                     class="object-center"/>
            </div>
            <h3 class="mt-2 text-base text-gray-700">{{ product.title }}</h3>
        </Link>
        <div class="min-h-[6rem] mb-2">
            <div
                :class="canAdd || onFavorites ? ((cartProduct || onFavorites) ? 'desktop:hidden' : 'desktop:group-hover:hidden') : ''"
            >
                <div class="flex flex-row flex-wrap my-1 justify-between items-center">
                    <p class="text-sm text-gray-600" v-if="product.subtitle">{{ product.subtitle }}</p>
                    <p class="text-sm text-gray-500 tracking-wider" v-if="product.manufacturerCode">{{
                            product.manufacturerCode
                        }}</p>
                </div>
                <p class="text-gray-900 text-lg leading-tight" v-if="product.inStock && product.price > 1">
                    <PriceWithUnits :product="product"/>
                </p>
            </div>
            <div
                :class="cartProduct || onFavorites ? 'flex' : 'desktop:hidden desktop:group-hover:flex'"
                v-if="canAdd || onFavorites"
                class="mt-2 w-full flex-col justify-center">
                <CartButton v-if="canAdd" :productId="product.productId"/>
                <PrimaryButton
                    @click="removeFromFavorites(product.productId)"
                    :inverted="true" v-if="onFavorites" class="mt-1 py-0.5">
                    Remove favorite
                </PrimaryButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, inject} from 'vue';
import {Link, usePage} from '@inertiajs/vue3';
import PriceWithUnits from "@/Components/PriceWithUnits.vue";
import CartButton from "@/Components/CartButton.vue";
import Labels from "@/Components/Labels.vue";
import {imageSize, removeFromFavorites} from "@/mixins";
import PrimaryButton from "@/Components/PrimaryButton.vue";

const route = inject('route');

const props = defineProps({
    product: Object,
});

const cartProduct = computed(() => Object.values(usePage().props.cart.products).find((el) => el.productId === props.product.productId) !== undefined);
const imageClasses = computed(() => props.product.cropImage ? 'group-hover:scale-125 transition transition-duration-300 object-cover' : 'object-contain');
const onFavorites = computed(() => route('products.favorites').endsWith(usePage().url));
const canAdd = computed(() => props.product.inStock && props.product.price > 1);

</script>
